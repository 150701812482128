import React from 'react'
import {Container,
    List,
    ListItem,
    ListItemText,
    Autocomplete,
    Button,
    TextField} from '@mui/material'
import {makeStyles} from '@mui/styles'

const styles=makeStyles({
    container:{
        top:"150px"
    }
})
export default function SkladFind(props){
    const classes=styles()
    //const {sklad}=props
    const[stoki,setStoki]=React.useState([])
    const[open,setOpen]=React.useState(false)
    const [selectedSklad,setSelectedSklad]=React.useState(0)
    const [positions,setPositions]=React.useState([])
    const [loading,setLoading]=React.useState(false)
  const sklads=[
    {name:'Момина чешма',id:"11"},
    {name:'Равногор',id:"5"}
    
  ];
    //const loading = open && stoki.length === 0;

    React.useEffect(() => {
        // console.log(sklad)
      const getBuf=()=>{
        setLoading(true)
         return fetch('https://sys.toner.bg/sklad/posapi.php?action=getAvailableGoodsInSklad&skladid='+sklads[selectedSklad].id)
         .then((data)=>{
             console.log(data)
             return data.json()
         })
         .then((data)=>{
             console.log(data)
            setStoki([...data])
            setLoading(false)
         })
         .catch(err => {
             // some error handling
           });
     }
         getBuf();
         // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [selectedSklad])
    // const handleOnChange=(input)=>{
        
    //     if(input.length===2){
    //         //let x=['1','2612','3261','4']
    //         setStoki([...x])
    //         setOpen(true)
    //     }
    //     else if(input.length===0){
    //         setStoki([])
    //         setPositions([])
    //     }

    // }
    return (
        <Container className={classes.container}>
            <Button
            onClick={
                ()=>
                {
                    setSelectedSklad(selectedSklad===sklads.length-1 ? 0:selectedSklad+1)
                    setLoading(true)
                }
              }>{sklads[selectedSklad].name}</Button>
            <Autocomplete
        id="searchpos"
        freeSolo
        getOptionLabel={(option) => option.productname.trim()}
        options={stoki}
        open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(option, value) => {
          if(value===null){
              setPositions([])
          }
          else{
          console.log(value)
          fetch('https://sys.toner.bg/sklad/posapi.php?action=getGoodsBySklad&pid='+value.productcode+'&skladid='+sklads[selectedSklad].id)
          .then((data)=>data.json())
          .then((lj)=>{
              setPositions([...lj])
          })
          }
        }}
      loading={loading}
        renderInput={(params) => <TextField {...params} label="Търсене" 
        // onChange={ev=>{
        //     if (ev.target.value !==''||ev.target.value!==null){
        //         handleOnChange(ev.target.value)
        //     }
        // }}
        />}
      />
            <List>
                
                    {positions.map((el,idx)=>(
                    <ListItem key={idx} button>
                        <ListItemText  primary={`Пале: ${parseInt(el.position).toFixed(0)}  Кашон: ${parseInt(el.cartno).toFixed(0)} Количество: ${el.qty} бр.`}/>
                    </ListItem>))}

                

            </List>

        
        
       
        </Container>
        
    )
}