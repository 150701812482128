import React from 'react'
import {Container,
    // List,
    // ListItem,
    // ListItemText,
    Autocomplete,
    TextField,
    Typography,
    Button,
    AppBar,
    Toolbar,
    IconButton,
    Divider,
    Dialog,
    Slide,
    Tabs,
    Tab
    } from '@mui/material'
import {makeStyles} from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles=makeStyles({
  root: {
    fontSize:'3rem',
    opacity: 1,
  },
  label:{
    fontSize:"3rem"
  },
  selected: {
    borderBottomWidth: 4,
    fontSize:'3rem',
    '& $wrapper': {
      opacity: 1,
    },
  },
  wrapper: {
    opacity: 0.7,
  },
});
export default function SkladIn(props){
    //const {sklad}=props
    const classes=styles()
    const [buffer,setBuffer]=React.useState([])
    const [loading,setLoading]=React.useState(false)
    const [selectedSklad,setSelectedSklad]=React.useState(0)
    //const [positions,setPositions]=React.useState([])
    const [available,setAvailable]=React.useState({})
    const [dialogOpen,setDialogOpen]=React.useState(false)
    const [pvalue,setPValue]=React.useState(-1)
    const [cvalue,setCValue]=React.useState(-1)
    const [qvalue,setQValue]=React.useState(-1)
    //const [values,setValues]=React.useState({productname:"",productid:""})
    const [completedTransfer,setCompletedTransfer] =React.useState(0)

  const sklads=[
    {name:'Момина чешма',id:"11"},
    {name:'Равногор',id:"5"}
    
  ];

    React.useEffect(() => {
       // console.log(sklad)
     const getBuf=()=>{
       setLoading(true)
        return fetch('https://sys.toner.bg/sklad/posapi.php?action=getBufferBySklad&skladid='+sklads[selectedSklad].id)
        .then((data)=>{
            //console.log(data)
            return data.json()
        })
        .then((data)=>{
           setBuffer([...data])
           setLoading(false)
        })
        .catch(err => {
            // some error handling
          });
    }
    
        getBuf();
        // eslint-disable-next-line react-hooks/exhaustive-deps
}, [selectedSklad,completedTransfer])
    const handleClose=()=>{
      setAvailable({})
      setDialogOpen(false)
    setPValue(-1)
    setQValue(-1)
    setCValue(-1)
  }
  const handleClosewithSave=()=>{
    if(pvalue>=0&&qvalue>=0&&cvalue>=0){
      var params= new URLSearchParams({
        action:"putBySklad",
        cartno:cvalue+1,
        palet:pvalue+1,
        qty:qvalue+1,
        skladid:sklads[selectedSklad].id,
        productcode:available.productid
      })
      console.log(params.toString())
      fetch("https://sys.toner.bg/sklad/posapi.php?"+params.toString(),)
      .then(data=>data.json())
      .then((res)=>{
        if(res.result==="OK"){
        setDialogOpen(false)
        setPValue(-1)
        setQValue(-1)
        setCValue(-1)
        setCompletedTransfer(completedTransfer+1)
        }
        else{
          alert("Грешка")
        }
      })
    
    }
    else{
      alert("Не са въведени всички данни")
    }
}
    const handleChangeP = (event, newValue) => {
      setPValue(newValue);
    };
    const handleChangeC = (event, newValue) => {
      setCValue(newValue);
    };
    const handleChangeQ = (event, newValue) => {
      setQValue(newValue);
    };
    return (
        <Container>
           <Dialog
        fullScreen
        open={dialogOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Добави 
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClosewithSave}>
              Запиши
            </Button>
          </Toolbar>
        </AppBar>

        {available.productname} max:{available.buffer}
        {/* <h3>Пале:</h3>
        <Pagination count={100}  siblingCount={20} variant="outlined" shape="rounded" size="large" hidePrevButton hideNextButton/>
        <Divider/>
        <h3>Кашон:</h3>
        <Pagination count={15}  siblingCount={15} variant="outlined" shape="rounded" size="large" hidePrevButton hideNextButton/>
        <Divider/>
        <h3>Брой касети:</h3>
        <Pagination count={parseInt(available.buffer)}  siblingCount={parseInt(available.buffer)} variant="outlined" shape="rounded" size="large" hidePrevButton hideNextButton/>
        <Divider/> */}
    <h3>Пале:{pvalue+1}</h3>
        <Tabs
       // className={classes.root}
  value={pvalue}
  onChange={handleChangeP}
  variant="scrollable"
  scrollButtons
  allowScrollButtonsMobile
  aria-label="scrollable force tabs example"
>
  
  {
      [...Array(100).keys()].map((el,idx)=>{
       return(
        <Tab key={idx}
         label={<span className={classes.label}>{idx+1}</span>} />
       ) 
      })
  }
 
</Tabs>
<Divider/>
<h3>Кашон:{cvalue+1}</h3>
        <Tabs
  value={cvalue}
  onChange={handleChangeC}
  variant="scrollable"
  scrollButtons
  allowScrollButtonsMobile
  aria-label="scrollable force tabs example"
>
  
  {
      [...Array(100).keys()].map((el,idx)=>{
       return(
        <Tab key={idx} label={<span className={classes.label}>{idx+1}</span>} />
       ) 
      })
  }
 
</Tabs>
<Divider/>
<h3>Брой:{qvalue+1}</h3>
        <Tabs
  value={qvalue}
  onChange={handleChangeQ}
  variant="scrollable"
  scrollButtons
  allowScrollButtonsMobile
  aria-label="scrollable force tabs example"
>
  
  {
      [...Array(parseInt(available.buffer)||0).keys()].map((el,idx)=>{
       return(
        <Tab key={idx} label={<span className={classes.label}>{idx+1}</span>} />
       ) 
      })
  }
 
</Tabs>
      
      </Dialog>
    
            <Button
            onClick={
                ()=>
                {
                    setSelectedSklad(selectedSklad===sklads.length-1 ? 0:selectedSklad+1)
                    setLoading(true)
                }
              }>{sklads[selectedSklad].name}</Button>
            <Autocomplete
            
        id="free-solo-demo"
        //freeSolo
        //value={values}
        options={buffer}
        getOptionLabel={(option) => option.productname.trim()+'-'+option.productid}
        renderInput={(params) => <TextField {...params} label="Търсене" />}
        onChange={(option, value) => {
          if(value!==null){
          //console.log(value)
          setAvailable(value)
          //setValues(value)
          // fetch('https://sys.toner.bg/sklad/posapi.php?action=getGoodsBySklad&pid='+value.productid+'&skladid='+sklads[selectedSklad].id)
          // .then((data)=>data.json())
          // .then((lj)=>{
          //     setPositions([...lj])
          // })
          }
        }
        }
      />
           {/* { 
           loading ? (<Typography>Изчакайте зарежда се ...</Typography>):(<List>
            { buffer.map((el,idx)=>(
             <ListItem key={idx} button>
                 <ListItemText primary={el.productname} secondary={el.buffer}/>
             </ListItem>)
            )
            }
           </List>)
        } */}
        {available.buffer ? (
        <Button
            onClick={()=>setDialogOpen(true)}
        >
          Свободни:{available.buffer}
        </Button>):null}
        {/* <List>
                
                {positions.map((el,idx)=>(
                <ListItem key={idx} button>
                    <ListItemText  primary={`Пале: ${parseInt(el.position).toFixed(0)}  Кашон: ${parseInt(el.cartno).toFixed(0)} Количество: ${el.qty} бр.`+''}/>
                </ListItem>))}
                
            

        </List> */}
        
        
       
        </Container>
    )
}