import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import {
  createTheme,
  AppBar,
  Toolbar,
  Typography,
  Container,
  ThemeProvider,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  BottomNavigation,
  BottomNavigationAction,
  //Box,
  //Button,
  CssBaseline,
  //Menu,
  //MenuItem
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import MenuIcon from '@mui/icons-material/Menu';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './App.css';
import SkladIn from './SkladIn';
import OrderOut from './OrderOut';
import SkladFind from './SkladFind';
import LogIn from './Login';


const theme = createTheme({
  palette: {
    mode : 'dark',
  },
});
const useStyles = makeStyles({
  stickToBottom: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
  cont:{
    marginTop:"70px",
    marginBottom:"70px"},
  
});






function App() {
  const classes=useStyles()
  const [drawerOpen,setDrawerOpen] = React.useState(false)
  const [value,setValue]=React.useState(0)
  //const [menuOpen,setMenuOpen]=React.useState(false)
  //const [selectedSklad,setSelectedSklad]=React.useState(0)
  // const sklads = [
  //   {name:'Момина чешма',id:"11"},
  //   {name:'Равногор',id:"5"}
    
  // ];
  const [loggedin,setLoggedIn] = React.useState(false)
  return !loggedin ? (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LogIn login={(k)=>setLoggedIn(k)}/>
      </ThemeProvider>
  ): (
    
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <Router>

  <SwipeableDrawer
  open={drawerOpen}
  onClose={()=>null}
  onOpen={()=>null}
  >
    <List>
      <ListItem button component={Link} to="/in"
      onClick={()=>{setDrawerOpen(false)}}>
        <ListItemText primary='Внасяне в склад'/>
      </ListItem>
      <ListItem button component={Link} to="/out"
      onClick={()=>{setDrawerOpen(false)}}>
        
        <ListItemText primary='Поръчки'
        />
         
      </ListItem>
      <ListItem button component={Link} to="/"
      onClick={()=>{setDrawerOpen(false)}}>
        
        <ListItemText primary='Търсене'
        />
      </ListItem>
      <ListItem button 
      onClick={async ()=>{
        
        await localStorage.setItem("login",null)
        await setLoggedIn(false)
        //console.log("logout")
        setDrawerOpen(false)
        }}>
        
        <ListItemText primary='Изход'
        />
      </ListItem>
     

    </List>
  </SwipeableDrawer>
<AppBar position='fixed'>
<Container maxWidth="xl" >
<Toolbar disableGutters>
<IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={()=>setDrawerOpen(!drawerOpen)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            ТонерБг Склад
          </Typography>
  {/* <Button
  onClick={
    ()=>setSelectedSklad(selectedSklad===sklads.length-1 ? 0:selectedSklad+1)
  }>{sklads[selectedSklad].name}</Button> */}

</Toolbar>
</Container>
</AppBar>
<Container className={classes.cont}>
<Routes>
          <Route path="/in" element={<SkladIn />}/>
          
          <Route path="/out" element={
            <OrderOut/>}/>
          <Route path="/" element={
            <SkladFind />}
          />
         
        </Routes>
        </Container>
       
        <BottomNavigation
        className={classes.stickToBottom}
  showLabels
  value={value}
  onChange={(event, newValue) => {
    setValue(newValue);
  }}
>
<BottomNavigationAction label="Търсене" icon={<RestoreIcon />}  component={Link} to="/" />
  <BottomNavigationAction label="Внасяне" icon={<FavoriteIcon />} component={Link} to="/in" />
  <BottomNavigationAction label="Изнасяне" icon={<LocationOnIcon />} component={Link} to="/out" />
</BottomNavigation>

        
    </Router>
    </ThemeProvider>
   
  );
}

export default App;
