import React,{useEffect} from 'react'
import {
    Container,
    TextField,
    Button    
} from '@mui/material'
import {makeStyles} from '@mui/styles'

    const useStyles = makeStyles(theme => ({
        root: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: theme.spacing(2),
      
          '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '300px',
          },
          '& .MuiButtonBase-root': {
            margin: theme.spacing(2),
          },
        },
      }));

export default function LogIn(props)
{
    const {login} = props
    const classes = useStyles();
    const [password,setPassword] = React.useState('')
    const validKeys=['123123','1212','1818']
    const [error,setError]=React.useState(false)

   useEffect(()=>{
       const fetchData = async ()=>{
       var k = await localStorage.getItem("login")
       //await setPassword(k ? k:'')
       console.log(k)
        if(validKeys.indexOf(k)!==-1)
        {
            localStorage.setItem("login",k)
            login(true)
        }
    }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleSubmit = e => {
        e.preventDefault();
        
        if(validKeys.indexOf(password)!==-1)
        {
            localStorage.setItem("login",password)
            login(true)
        }
        else
        {
                setPassword('')
                setError(true)
                
        }
        //login(true)
        //handleClose();
      };
    


    return(
        <Container>
             <form className={classes.root} onSubmit={handleSubmit}>
      <TextField
        label="Ключ"
        variant="filled"
        required
        error={error}
        value={password}
        onChange={e => setPassword(e.target.value)}
        helperText={error ? "Грешен ключ":""}
      />
     <Button type="submit" variant="contained" color="primary">
          ВХОД
        </Button>
      </form>
        </Container>
    )
}